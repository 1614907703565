type Data = Blob | FormData

/**
 * @example
 * const blob = new Blob([JSON.stringify(data)], {
 *     type: 'application/json'
 *   })
 * }
 * sendBeacon('/url', blob)
 *
 * @example
 * const formData = new FormData()
 * sendBeacon('/url', formData)
 */

export function sendBeacon(url: string, data?: Data) {
  if (typeof navigator.sendBeacon === 'function') {
    try {
      navigator.sendBeacon(url, data)
    } catch (error) {
      if (error instanceof Error && error.name === 'SecurityError') {
        // Bug in 2021 browsers https://bugs.chromium.org/p/chromium/issues/detail?id=490015
      } else {
        throw error
      }
    }
  }
}
