import { sendBeacon } from '@/utils/core.client'

export function page() {
  const formData = new FormData()
  formData.append('context', JSON.stringify(context()))
  formData.append('name', location.pathname)
  formData.append('source', 'www')
  sendBeacon('/analytics/page', formData)
}

export function track(event: string, properties = {}) {
  const formData = new FormData()
  formData.append('context', JSON.stringify(context()))
  formData.append('event', event)
  formData.append('properties', JSON.stringify(properties))
  formData.append('source', 'www')
  sendBeacon('/analytics/track', formData)
}

export function context(ctx = {}) {
  return {
    ...ctx,
    page: {
      path: location.pathname,
      referrer: document.referrer,
      search: location.search,
      title: document.title,
      url: canonicalUrl()
    }
  }
}

function canonicalUrl(): string {
  const canon = canonical()

  if (canon) {
    return canon.includes('?') ? canon : `${canon}${location.search}`
  }

  const url = window.location.href
  const i = url.indexOf('#')

  return i === -1 ? url : url.slice(0, i)
}

function canonical(): string | null {
  return document.querySelector('link[rel="canonical"]')?.getAttribute('href') ?? null
}
